import { Industries } from '@interfaces/industries.interface';

export const INDUSTRIES: Industries[] = [
  {
    id: 'finance',
    color: 'var(--orange)',
    header: 'Finance & Banking',
    text: 'Discover how our innovative technology solutions are reshaping the finance and banking sector. From streamlined digital banking experiences to advanced risk management systems, we empower financial institutions to thrive in the digital age.',
    tags: [
      'Investments',
      'Financial analysis',
      'Payment systems',
      'Insurance',
      'Financial technology (FinTech)',
      'Digital platforms',
      'Accounting',
    ],
  },
  {
    id: 'healthcare',
    color: 'var(--olive)',
    header: 'Healthcare & Life Sciences',
    text: 'Empower your healthcare organization with cutting-edge technology solutions tailored for the Healthcare & Life Sciences industry, enabling seamless patient care, data management, and research advancement.',
    tags: [
      'Medicine',
      'Biology',
      'Pharmaceuticals',
      'Medical research',
      'Medical devices',
      'Genetics',
      'Neuroscience',
      'Healthy lifestyle',
      'Medical technologies',
    ],
  },
  {
    id: 'ecommerce',
    color: 'var(--red)',
    header: 'eCommerce & Retail',
    text: 'Elevate your eCommerce & Retail business with innovative digital solutions that enhance customer experience, streamline operations, and drive sales growth in the competitive online marketplace.',
    tags: [
      'Retail',
      'Online shopping',
      'Electronic payment system',
      'Mobile commerce',
      'Delivery logistics',
      'Customer service',
      'Online store',
      'Sales marketing',
      'Inventory management',
    ],
  },
  {
    id: 'innovations',
    color: 'var(--blue)',
    header: 'Hi-Tech & Innovations',
    text: 'Stay ahead of the curve in the fast-paced world of Hi-Tech & Innovations with our custom solutions, designed to fuel your innovation initiatives, accelerate product development, and drive technological advancements.',
    tags: [
      'Artificial intelligence',
      'Robotics',
      'Automation',
      'Blockchain',
      'Internet of Things (IoT)',
      'Software development',
      'Cybersecurity',
      'Technology startup',
    ],
  },
  {
    id: 'education',
    color: 'var(--green)',
    header: 'eLearning & Education',
    text: 'Transform the future of education with our eLearning & Education solutions, providing interactive platforms, personalized learning experiences, and data-driven insights to enhance student engagement and outcomes.',
    tags: [
      'Online courses',
      'Virtual classrooms',
      'Learning platforms',
      'Distance learning',
      'Interactive learning',
    ],
  },
  {
    id: 'media',
    color: 'var(--red)',
    header: 'Media & Entertainment',
    text: 'Engage audiences and captivate viewers with our Media & Entertainment solutions, offering immersive digital experiences, content management systems, and analytics tools to drive content creation, distribution, and monetization strategies.',
    tags: [
      'Movies',
      'Television',
      'Music',
      'Film industry',
      'Games',
      'Social media',
      'Concerts and performances',
      'Video games',
    ],
  },
  {
    id: 'logistics',
    color: 'var(--orange)',
    header: 'Logistics & Transportation',
    text: 'Optimize your supply chain and transportation networks with our Logistics & Transportation solutions, delivering real-time visibility, operational efficiency, and cost savings across the entire logistics ecosystem.',
    tags: [
      'Freight',
      'Supply chain',
      'Cargo transportation',
      'Railway logistics',
      'Maritime logistics',
      'Air logistics',
      'Warehouse management',
      'Supply chain management',
    ],
  },
];
